export const INVESTOR_ID = 20150627000347;
export const INTERMEDIARY_ID = 20170825001241;
export enum SummaryKeyInvestments {
  Payment_Out = 'bankPayment',
  Receipt_In = 'bankReceipt',
  Face_Value = 'faceValue',
  Buy_Rate = 'buyRate',
  Commission = 'commission',
  Months = 'months',
  Balance = 'balance',
  Yield = 'yield',
}

export enum SummaryKeyFxrate {
  BuyRate = 'buyRate',
  Gbp = 'gbp',
  Usd = 'usd',
  Eonia = 'eonia',
  Libor = 'libor',
  Obfr = 'obfr',
}

export enum SummaryKeyTradeTransactions {
  PTV = 'ptv',
  SellRate = 'sellRate',
  EDSO = 'edso',
  RDSO = 'rdso',
  ADSO = 'adso',
  Months = 'months',
}

export enum SummaryKeyBankDeatils {
  bankId = 'bankId',
  bankName = 'bankName',
  sortCode = 'sortCode',
  account = 'account',
  address = 'address',
  swift = 'swift',
  iban = 'iban',
}
