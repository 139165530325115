import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { getFormData } from 'src/app/shared/helpers/utils';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ReferFriendFormProvider } from '../../referFriend-form-provider';
import { ReferFriendHttpService } from '../../common/services/referFriend-http.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-refer-friend',
  templateUrl: './refer-friend.component.html',
  styleUrls: ['./refer-friend.component.scss'],
})
export class ReferFriendComponent {
  referenceForm: FormGroup;
  // todo need remove when can get from backend side
  tooltipData = {
    name: 'Name',
    email: 'Email',
  };
  isSubmitted = false;
  constructor(
    private referFriendFormProvider: ReferFriendFormProvider,
    public activeModal: NgbActiveModal,
    public referFriendHttpService: ReferFriendHttpService,
  ) {}

  ngOnInit(): void {
    this.referenceForm = this.referFriendFormProvider.initReferenceForm();
  }

  onSubmit() {
    this.isSubmitted = true;
    if (this.referenceForm.invalid) {
      return;
    }
    let rawData = this.referenceForm.value;
    [rawData.first_name, ...rawData.last_name] = rawData.name.split(' ');
    rawData.last_name = rawData.last_name.join(' ');
    rawData.name;
    rawData = getFormData(rawData);
    this.sendMail(rawData);
  }

  sendMail(data: any): void {
    this.referFriendHttpService.referFriend(data).subscribe({
      next: (response: any) => {
        this.closeModal();
        Swal.fire({
          icon: 'success',
          text: 'Email successfully sent.',
          showConfirmButton: false,
          timer: 5000,
        });
      },
      error: (error) => {
        Swal.fire({
          icon: 'error',
          text: error.error.details || error.error.message,
          showConfirmButton: true,
          confirmButtonText: 'Ok',
        });
      },
    });
  }

  closeModal() {
    this.activeModal.dismiss();
  }
}
