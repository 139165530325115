import { Directive, ElementRef, HostListener, OnDestroy, OnInit } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Directive({
  selector: '[NumberWithCommas]',
  providers: [DecimalPipe],
})
export class NumberWithCommasDirective {
  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    this.formatInputWithCommas(value);
  }

  formatInputWithCommas(value: string) {
    let dots = value.split('.').length - 1;
    let formattedValue = value;
    dots = dots < 0 ? 0 : dots;
    if (dots > 1) {
      formattedValue = value.slice(0, -1);
    }
    formattedValue = formattedValue.replace(/,/g, '').replace(/\s/g, '');
    const parts = formattedValue.split('.');
    if (parts.length > 2) {
      formattedValue = parts[0] + '.' + parts.slice(1).join('');
    }
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    formattedValue = parts.join('.');
    formattedValue = this.transform(formattedValue);
    this.el.nativeElement.value = formattedValue;
    return formattedValue;
  }

  // If there's a decimal part
  transform(value: string): string {
    const parts = value.split('.');
    if (parts.length > 1) {
      const integerPart = parts[0];
      const decimalPart = parts[1].substring(0, 2); // Take only the first two characters of the decimal part
      return integerPart + '.' + decimalPart;
    } else {
      return value;
    }
  }
}