import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { getFormData } from 'src/app/shared/helpers/utils';
import { Investment } from 'src/app/shared/interfaces/investments-response.interface';
import Swal from 'sweetalert2';
import { InvestmentsHttpService } from '../../common/services/investments-http.service';
import { InvestmentsFormProvider } from '../../investments-form-provider';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpService } from 'src/app/services/http.service';
import * as moment from 'moment';
import { INVESTOR_ID } from 'src/app/shared/enums/common.enum';

@Component({
  selector: 'app-add-edit-investments',
  templateUrl: './add-edit-investments.component.html',
  styleUrls: ['./add-edit-investments.component.scss'],
})
export class AddEditInvestmentsComponent {
  investmentForm: FormGroup;
  // todo need remove when can get from backend side
  tooltipData = {
    ccy: 'Ccy',
    faceValue: 'Face Value',
    comments: 'Comments',
    months: 'Months',
    type: 'Type',
    transactionType: 'Transaction Type',
    date: 'Date',
    product: 'Product',
    ledger: 'Ledger',
    buyRate: 'Buy Rate',
    commission: 'Commission',
    investorId: 'Investor',
    intermediaryId: 'Intermediary',
  };
  isSubmitted = false;
  type: { id: string; name: string }[];
  allCcyData: { id: string; name: string }[];
  transactionType: { id: string; name: string }[];
  product: { id: string; name: string }[];
  ledgerOwner: { id: string; name: string }[];
  investmentData: Investment;
  allCurrencyBankAccountData: any = [];
  investors: { id: string; name: string }[];
  investorId = INVESTOR_ID;
  constructor(
    private investmentsHttpService: InvestmentsHttpService,
    private investmentsFormProvider: InvestmentsFormProvider,
    public activeModal: NgbActiveModal,
    private http: HttpService,
  ) {}

  ngOnInit(): void {
    this.investmentForm = this.investmentsFormProvider.initInvestmentForm(this.investmentData);
    this.getDropDownsData();
  }

  onSubmit() {
    const fieldsToFormat = ['date'];
    this.isSubmitted = true;
    if (this.investmentForm.invalid) {
      return;
    }
    let rawData = this.investmentForm.value;
    rawData = getFormData(rawData);
    fieldsToFormat.forEach((field) => {
      const date = rawData[field];
      if (date) {
        rawData[field] = field === 'months' ? moment(date).format('MM') : moment(`${date.year}-${date.month}-${date.day}`, 'YYYY-MM-DD').format('YYYY-MM-DD');
      }
    });
    rawData.investorId = this.investorId;
    rawData.faceValue = rawData.faceValue.replace(/,/g, '');
    this.doCreate(rawData);
  }

  doCreate(data: any): void {
    this.investmentsHttpService.postData('investments', data).subscribe({
      next: (response: any) => {
        this.closeModal();
        this.investmentForm.reset();
        Swal.fire({
          icon: 'success',
          text: 'Investment successfully added.',
          showConfirmButton: false,
          timer: 5000,
        });
      },
      error: (error) => {
        Swal.fire({
          icon: 'error',
          text: error.error.details || error.error.message,
          showConfirmButton: true,
          confirmButtonText: 'Ok',
        });
      },
    });
  }

  onSelect(event: any): void {
    this.isSubmitted = false;
  }

  private getDropDownsData(): void {
    this.investmentsHttpService.getInvestmentsFilterData().subscribe((investmentsFilterData) => {
      investmentsFilterData.types.sort((a, b) => a.localeCompare(b));
      this.type = [
        ...(investmentsFilterData.types.map((type) => ({
          id: type,
          name: type,
        })) || []),
      ];

      let foundInvestor: any = null;
      let foundIntermediary: any = null;
      for (let intermediary of investmentsFilterData.intermediariesWithInvestors) {
        let investor = intermediary.investors.find((investor: any) => investor.id == this.investorId);
        if (investor) {
          foundInvestor = investor;
          foundIntermediary = intermediary;
          break;
        }
      }
      if (!this.investors) {
        this.investors = [];
      }
      if (foundInvestor && foundIntermediary) {
        this.investors.push({
          id: foundIntermediary.id,
          name: foundIntermediary.name,
        });
      }
      this.investors.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });

      this.transactionType = [
        ...(investmentsFilterData.transactionTypes.map((transactionType) => ({
          id: transactionType,
          name: transactionType,
        })) || []),
      ];

      this.product = [
        ...(investmentsFilterData.products.map((product) => ({
          id: product,
          name: product,
        })) || []),
      ];

      this.ledgerOwner = [
        ...(investmentsFilterData.ledgers.map((ledger) => ({
          id: ledger.id,
          name: ledger.name,
        })) || []),
      ];
    });
    this.http.getCurrencyWithBank().subscribe((response: any) => {
      this.allCcyData = [
        ...(response.map((ccy: any) => ({
          id: ccy.currency,
          name: ccy.currency,
        })) || []),
      ];
    });
  }

  closeModal() {
    this.activeModal.dismiss();
  }
}
