export const PercentValueFormatter = (params: any): string => {
  let originalValue = params.value;
  if (originalValue?.toString().indexOf('%') == -1 && originalValue.toString().indexOf('-') != 0) {
    let formattedValue = '-';
    if (originalValue != 0 && originalValue != null) {
      formattedValue = originalValue.toLocaleString('en-US') + '%';
    }
    return formattedValue;
  } else return originalValue;
};

export const PercentValueFormatter2 = (params: any): string => {
  let originalValue = params.value;
  if (originalValue.toString().indexOf('%') == -1 && originalValue.toString().indexOf('-') != 0) {
    let formattedValue = '-';
    if (originalValue != 0 && originalValue != null) {
      formattedValue =
        (originalValue * 100).toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }) + '%';
    }
    return formattedValue;
  } else return originalValue;
};

export const PercentValueFormatter3 = (formatedValue: any): string => {
  let originalValue = formatedValue;
  if (originalValue.toString().indexOf('%') == -1 && originalValue.toString().indexOf('-') != 0) {
    let formattedValue = '-';
    if (originalValue != 0 && originalValue != null) {
      formattedValue = originalValue.toLocaleString('en-US');
    }
    return formattedValue + '%';
  } else return originalValue;
};

export const PercentValueFormatter4 = (params: any): string => {
  let formattedValue = '';
  if(params.value){
    let originalValue = +params.value * 100;
    if (originalValue == 0 || originalValue == null || params.value == '-') {
        return formattedValue;
    }
    if (originalValue && originalValue < 10) {
        formattedValue =
            originalValue.toLocaleString('en-US', {
                minimumFractionDigits: 3,
                maximumFractionDigits: 3,
            }) + '%';
    } else if (originalValue && originalValue > 10 && originalValue < 100) {
        formattedValue =
            originalValue.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }) + '%';
    }
    else {
        formattedValue =
            originalValue.toLocaleString('en-US', {
                minimumFractionDigits: 1,
                maximumFractionDigits: 1,
            }) + '%';
    }
  }
    return formattedValue;
}

export const PercentValueFormatter5 = (params: any): string => {
  let originalValue = params.value;
  let formattedValue = '-';
  if (originalValue == 0 || originalValue == null || params.value == '-') {
      return formattedValue;
  }
  if (originalValue && originalValue < 10) {
      formattedValue =
          originalValue.toLocaleString('en-US', {
              minimumFractionDigits: 3,
              maximumFractionDigits: 3,
          }) + '%';
  } else if (originalValue && originalValue > 10 && originalValue < 100) {
      formattedValue =
          originalValue.toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
          }) + '%';
  }
  else {
      formattedValue =
          originalValue.toLocaleString('en-US', {
              minimumFractionDigits: 1,
              maximumFractionDigits: 1,
          }) + '%';
  }
  return formattedValue;
}
