import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  title = '';
  loginDisplay = false;
  loginText = 'Login';
  private readonly _destroying$ = new Subject<void>();
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';
  rememberMe: boolean = false;
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    if (this.isLoggedIn()) {
      this.router.navigate(['/dashboard']);
    }
    this.rememberMe = this.isRememberMe();
    this.initLoginForm();
  }

  initLoginForm() {
    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  private isLoggedIn(): boolean {
    return !!localStorage.getItem('token');
  }

  get f() {
    return this.loginForm.controls;
  }

  login() {
    this.submitted = true;
    if (this.loginForm.invalid) return;
    const { username, password } = this.loginForm.value;

    if (username == 'jdugdale001' && password == 'Test@123') {
      this.loading = true;
      this.loginText = 'Please Wait ...';
      this.authService.login({ username, password }).subscribe(
        (response) => {
          this.authService.setToken(response.token);
          this.authService.rememberMe(this.rememberMe);
          this.router.navigate(['/dashboard']);
        },
        (error) => {
          console.error(error);
          this.loginText = 'Login';
          Swal.fire({
            icon: 'error',
            text: error,
            showConfirmButton: true,
            confirmButtonText: 'Ok',
          });
        },
      );
    } else {
      this.loginText = 'Login';
      Swal.fire({
        icon: 'error',
        text: 'Please enter a correct username and password',
        showConfirmButton: true,
        confirmButtonText: 'Ok',
      });
    }
  }
  isRememberMe(): boolean {
    const rememberMeValue = localStorage.getItem('rememberMe');
    if (rememberMeValue !== null) {
      return JSON.parse(rememberMeValue);
    }
    return false;
  }

  onRememberMeChange(event: any) {
    this.rememberMe = event.target.checked;
  }
  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
    this.loginForm.reset();
  }
}