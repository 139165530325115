import { ChangeDetectorRef, Component, ElementRef, EventEmitter, NgZone, Output, Renderer2, ViewChild } from '@angular/core';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as moment from 'moment';
import { FS_PDF_Data } from 'src/app/financial-statement/common/interfaces/fs_pdf_interface';
import { PercentValueFormatter4 } from '../helpers/percent-value-formatter';
import { numberValueFormatter } from '../helpers/number-value-formatter';

@Component({
  selector: 'app-html-to-pdf',
  templateUrl: './html-to-pdf.component.html',
  styleUrls: ['./html-to-pdf.component.scss'],
})
export class HtmlToPdfComponent {
  @ViewChild('pdfTable', { static: false }) pdfTable: ElementRef;
  typeDemand: any[] = [];
  typeFixed: any[] = [];
  typeTerm: any[] = [];
  fs_Pdf_Data: FS_PDF_Data;
  isSingleType: boolean = true;
  logoUrl = '../../../assets/images/logos/logo-bank-statement.png';
  stampUrl = '../../../assets/images/logos/stamp-bank-statement.png';
  @Output() pdfGenerated: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor(
    private renderer: Renderer2,
    private elRef: ElementRef,
  ) {}

  public async downloadAsPDF(fileName: string, fs_Pdf_Data: any) {
    const data = this.pdfTable.nativeElement;
    this.fs_Pdf_Data = fs_Pdf_Data;
    this.isSingleType = this.fs_Pdf_Data.isSingleType;
    this.typeDemand = fs_Pdf_Data.dataList.typeDemand;
    this.typeFixed = fs_Pdf_Data.dataList.typeFixed;
    this.typeTerm = fs_Pdf_Data.dataList.typeTerm;
    const allData = fs_Pdf_Data.dataList.typeDemand.concat(fs_Pdf_Data.dataList.typeFixed).concat(fs_Pdf_Data.dataList.typeTerm);
    if (allData.length === 0) return;

    const doc = new jsPDF('p', 'pt', 'a4');
    const options = {
      background: 'white',
      scale: 3,
    };

    const pageHeight = doc.internal.pageSize.getHeight();
    const margin = 15;
    const headerHeight = 20;
    const footerHeight = 40;
    const effectivePageHeight = pageHeight - headerHeight - footerHeight - 2 * margin;

    const renderPage = async (htmlContent: string, pageNumber: number, totalPages: number) => {
      data.innerHTML = htmlContent;
      const canvas = await html2canvas(data, options);
      const imgWidth = doc.internal.pageSize.getWidth();
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const imgData = canvas.toDataURL('image/png');
      let position = margin + headerHeight;
      doc.addImage(imgData, 'PNG', margin, position, imgWidth - 2 * margin, Math.min(effectivePageHeight, imgHeight));
      this.addHeader(doc);
      this.addFooter(doc, pageNumber, totalPages);
    };

    const calculateTotalPages = async (allData: string | any[]) => {
      let pageNumber = 1;
      let currentType = '';
      let totalPages = 0;
      let showSummaryTable = true;

      for (let index = 0; index < allData.length; ) {
        let recordsPerPage = pageNumber === 1 ? 25 : 33;
        let pagedData: any[] = [];

        if (allData[index].type !== currentType) {
          currentType = allData[index].type;
          showSummaryTable = true;

          if (currentType === 'Term' && pagedData.length > 0) {
            totalPages++;
          }
        } else {
          showSummaryTable = false;
        }

        while (index < allData.length && pagedData.length < recordsPerPage) {
          if (allData[index].type !== currentType && pagedData.length > 0) {
            break;
          }
          pagedData.push(allData[index]);
          index++;
        }

        totalPages++;
        pageNumber++;
      }

      return totalPages;
    };

    const totalPages = await calculateTotalPages(allData);

    const generatePDF = async (allData: string | any[]) => {
      let pageNumber = 1;
      let currentType = '';
      let showSummaryTable = true;

      for (let index = 0; index < allData.length; ) {
        let recordsPerPage = pageNumber === 1 ? 25 : 33;
        let pagedData: any[] = [];

        if (allData[index].type !== currentType) {
          currentType = allData[index].type;
          showSummaryTable = true;

          if (currentType === 'Term' && pagedData.length > 0) {
            doc.addPage();
            pageNumber++;
          }
        } else {
          showSummaryTable = false;
        }

        while (index < allData.length && pagedData.length < recordsPerPage) {
          if (allData[index].type !== currentType && pagedData.length > 0) {
            break;
          }
          pagedData.push(allData[index]);
          index++;
        }

        let htmlContent = this.htmlTable(pagedData, pageNumber, totalPages, showSummaryTable, currentType);
        showSummaryTable = false;
        await renderPage(htmlContent, pageNumber, totalPages);

        if (index < allData.length) {
          doc.addPage();
          pageNumber++;
        }
      }
    };

    await generatePDF(allData);

    const pdfBlob = doc.output('blob');
    const pdfURL = URL.createObjectURL(pdfBlob);
    window.open(pdfURL, '_blank');
    this.pdfGenerated.emit(true);
  }

  htmlTable(typeList: any, pageNumber: number, totalPages: number, showSummaryTable: boolean, currentType: string) {
    const { userData, summaryData } = this.fs_Pdf_Data;
    const demandId = this.typeDemand[this.typeDemand.length - 1]?.id;
    const fixedId = this.typeFixed[this.typeFixed.length - 1]?.id;
    const termId = this.typeTerm[this.typeTerm.length - 1]?.id;
    let isLastIndex = false;
    const existsInTypeList = typeList.some((item: any) => (item.id == demandId || item.id == fixedId || item.id == termId) && item.type == currentType);
    if (existsInTypeList) {
      isLastIndex = true;
    } else {
      isLastIndex = false;
    }
    const maturityDate = currentType === 'Demand' ? (summaryData.maturityDateDemand ? new Date(summaryData.maturityDateDemand).toISOString().split('T')[0] : '') : currentType === 'Fixed' ? (summaryData.maturityDateFixed ? new Date(summaryData.maturityDateFixed).toISOString().split('T')[0] : '') : currentType === 'Term' ? (summaryData.maturityDateTerm ? new Date(summaryData.maturityDateTerm).toISOString().split('T')[0] : '') : '';
    const pinnedBalance = currentType === 'Demand' ? summaryData.pinnedDemandBalance : currentType === 'Fixed' ? summaryData.pinnedFixedBalance : currentType === 'Term' ? summaryData.pinnedTermBalance : '';
    const pinnedYield = currentType === 'Demand' ? summaryData.pinnedDemandYield : currentType === 'Fixed' ? summaryData.pinnedFixedYield : currentType === 'Term' ? summaryData.pinnedTermYield : '';
    const totalYieldValue = currentType === 'Demand' ? summaryData.totalYieldDemand : currentType === 'Fixed' ? summaryData.totalYieldFixed : currentType === 'Term' ? summaryData.totalYieldTerm : '';
    const header = `
      <tr>
        <td colspan="3" style="text-align: center;"><h1 style="font-weight: 600;">Financial Statement</h1></td>
      </tr>
      <tr>
        <td height="30px"></td>
      </tr>
      <tr>
        <td style="vertical-align: top;">
          <div class="account-info">
            <h4>${userData.name}</h4>
            <p>${userData.address}</p>
          </div>
        </td>
        <td>
          <table class="statement-header-table">
            <tr>
              <td>
                <p>Bank Name</p>
                <p>SWIFT BIC</p>
                <p>IBAN</p>
                <p style="font-weight: 600; margin-top: 20px;">IAA</p>
                <p style="font-weight: 600;">RI</p>
              </td>
              <td>
                <p>:</p>
                <p>:</p>
                <p>:</p>
                <p style="font-weight: 600; margin-top: 20px">:</p>
                <p style="font-weight: 600;">:</p>
              </td>
              <td>
                <p>&nbsp;${userData.bankName}</p>
                <p>&nbsp;${userData.swiftBic}</p>
                <p>&nbsp;${userData.iBan}</p>
                <p style="font-weight: 600; margin-top: 20px">&nbsp;${userData.intermediaryId_IAA}</p>
                <p style="font-weight: 600;">&nbsp;${userData.investorId_RI}</p>
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr>
        <td style="height: 20px;"></td>
      </tr>
    `;

    const summaryTable = showSummaryTable
      ? `
      <table class="data-table overide-statement">
        <thead>
          <tr>
            <th colspan="4">Statement Overview</th>
          </tr>
        </thead>
        <tbody>
           <tr>
            <td style="font-weight: 700; font-size:18px;">Total Yield</td>
            <td style="text-align: right;" >${numberValueFormatter({ value: totalYieldValue })}</td>
            <td style="font-weight: 700; font-size:18px;">Next Maturity Date</td>
            <td>${maturityDate}</td>
          </tr>
        </tbody>
      </table>
    `
      : '';

    const footer = `
      <tfoot>
        <tr>
          <td colspan="8" style="text-align: right; padding-right: 15px; font-weight: 700; font-size:18px;">Total Yield : &nbsp; ${pinnedYield}</td>
          <td style="text-align: right; padding-right: 15px; font-weight: 700; font-size:18px;">${pinnedBalance}</td>
        </tr>
      </tfoot>
    `;

    return `
      <header>
        <table style="width: 100%;">
          <tr>
            <td style="text-align: left; vertical-align: top;"></td>
            <td style="float: right;">
              <p style="line-height: 22px; font-size: 17px; font-weight: 600; margin-bottom: 2px;">Credebt Exchange Limited</p>
              <p style="line-height: 22px; font-size: 15px;">15A Baggotrath Place, 15 - 16<br> Lower Baggot Street,<br>Dublin D02 NX49, Ireland</p>
              <p style="line-height: 22px; font-size: 15px;">Phone : +353 (1) 685-3600</p>
              <p style="line-height: 22px; font-size: 15px;">Email: yield@investabill.com</p>
            </td>
          </tr>
          <tr>
            <td height="25px"></td>
          </tr>
          ${pageNumber === 1 ? header : ''}
        </table>
        <hr>
      </header>
      <section class="transactions">
        <div class="data-table-outer">
          ${summaryTable}
          <table class="data-table">
            <thead>
              <tr>
                <th colspan="10">Detailed Statement</th>
              </tr>
              <tr>
                <th>Date</th>
                <th>Type</th>
                <th>Months</th>
                <th>Maturity Date</th>
                <th>Buy Rate</th>
                <th>Payments</th>
                <th>Receipts</th>
                <th>Yield</th>
                <th>Balance</th>
              </tr>
            </thead>
            <tbody>
              ${typeList
                .map(
                  (item: any, index: number) => `
               <tr>
                  <td style="color: #000;">${new Date(item.date).toISOString().split('T')[0]}</td>
                  <td style="color: #000;">${item.type ? item.type : '-'}</td>
                  <td style="color: #000;">${item.months ? item.months : '-'}</td>
                  <td style="color: #000;">${item.maturityDate ? new Date(item.maturityDate).toISOString().split('T')[0] : '-'}</td>
                  <td  style="color: #000;" class="text-right">${item.buyRate ? PercentValueFormatter4({ value: item.buyRate }) : '-'}</td>
                  <td style="color: #000;" class="text-right">${item.paymentAmount ? numberValueFormatter({ value: item.paymentAmount }) : '-'}</td>
                  <td style="color: #000;" class="text-right">${item.receiptAmount ? numberValueFormatter({ value: item.receiptAmount }) : '-'}</td>
                  <td style="color: #000;" class="text-right">${item.yield ? numberValueFormatter({ value: item.yield }) : '-'}</td>
                  <td style="color: #000;" class="text-right">${item.balance ? numberValueFormatter({ value: item.balance }) : '-'}</td>
                </tr>`,
                )
                .join('')}
            </tbody>
            ${isLastIndex ? footer : ''}
          </table>
        </div>
      </section>
      <style>
        img, ul, h1, h2, h3, h4, h5, h6, p { margin: 0px; padding: 0px; }
        * { margin: 0px; padding: 0px; }
        a { text-decoration: none; transition: all .5s ease-in-out; }
        a:hover { text-decoration: none; }
        .statement { width: 1050px; margin: auto; background: #fff; padding: 10px 40px; position: relative; }
        h1 { font-size: 24px; font-weight: 500; }
        .bank-name, .statement-date { margin: 5px 0; }
        .account-info h4 { font-size: 22px; font-weight: 700; }
        .account-info p { font-size: 16px; margin-top: 8px; }
        .data-table-outer { margin-top: 20px; margin-bottom: 40px; }
        .data-table.overide-statement { margin-bottom: 20px; }
        .data-table { width: 100%; border-collapse: collapse; border: 1px solid #3d4856; }
        .data-table tr:nth-child(even) { background-color: #f2f2f2; }
        .data-table th { background: #3d4856; color: #fff; }
        .data-table, .data-table th, .data-table td { border: 1px solid #54667a; }
        .data-table th, .data-table td { padding: 5px 5px; text-align: center; font-size: 15px;}
        h2 { font-size: 22px; font-weight: 500; margin-bottom: 6px; }
        .statement-header-table { margin-top: 0; width: 350px; margin-left: auto; }
        .investor-detail p { padding-top: 8px; }
        .investor-detail.summery { padding-top: 12px; }
        .investor-detail.summery p { font-weight: 600; }
        .investor-detail p span { display: inline-block; width: 50px; }
        .statement-header-table p { margin-top: 8px; font-size: 16px; min-height: 18px; }
        .transactions { margin-top: 0px; padding-top: 0px; }
        .total-text { display: block; text-align: right; width: 112px; }
        .data-table .text-right { text-align: right; }
        .statement.statement-2 .data-table-outer { height: unset; }
        .stamp-and-date { background: rgba(235, 255, 241, 1); }
        .statement .stamp-and-date { background-color: #fff; text-align: right; margin-top: 60px; }
        .statement .stamp-and-date p { font-size: 16px; padding-top: 10px; font-weight: 600; }
        .pagination { padding: 0px 0px; position: absolute; bottom: 0; right: 0; }
        .pagination ul { display: flex; align-items: center; justify-content: end; list-style: none; column-gap: 12px; }
        .pagination ul li a { color: #000; }
        .white-space { height: 250px; }
      </style>
    `;
  }

  addHeader(doc: jsPDF) {
    const margin = 15;
    const imageWidth = 130; // Adjust width as needed
    const imageHeight = 40; // Adjust height as needed
    doc.addImage(this.logoUrl, 'PNG', margin, margin, imageWidth, imageHeight);
  }

  addFooter(doc: jsPDF, pageNumber: number, totalPages: number) {
    const margin = 2;
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const currentDate = moment().format('YYYY-MM-DD'); // Get the current date in the required format
    doc.setFontSize(10);
    doc.text(`Page ${pageNumber} of ${totalPages}`, pageWidth - margin - 70, pageHeight - margin - 20);

    // Add stamp to the last page
    if (pageNumber === totalPages) {
      const stampWidth = 50;
      const stampHeight = 50;
      const stampX = pageWidth - margin - stampWidth;
      const stampY = pageHeight - margin - stampHeight;
      doc.addImage(this.stampUrl, 'PNG', stampX - 20, stampY - 60, stampWidth, stampHeight);

      // Add the date below the stamp image
      doc.setFontSize(10);
      doc.setFont('helvetica', 'bold');
      doc.text(currentDate, stampX - 20, stampY - 50 + stampHeight + 10);
    }
  }
}
