import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, DatePipe, DecimalPipe, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { FullComponent } from './layouts/full/full.component';
import { NavigationComponent } from './shared/header/navigation.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { AppComponent } from './app.component';
import { SpinnerComponent } from './shared/spinner.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { provideUserIdleConfig } from 'angular-user-idle';
import { environment } from '../../src/environments/environment';
import { LoginComponent } from './login/login.component';
import { UploadsComponent } from './uploads/uploads/uploads.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgSelectModule } from '@ng-select/ng-select';
import { AgGridAngular } from '@ag-grid-community/angular';
import { ModuleRegistry } from '@ag-grid-community/core';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { SharedModule } from './shared/shared.module';
import { AuthInterceptor } from './interceptors/auth-interceptor';
import { AuthService } from './services/auth.service'; 
import { AuthGuard } from './auth.gaurd';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { JwtModule } from '@auth0/angular-jwt';
import { ChunkPipe } from './shared/directives/chunk.directive';
LicenseManager.setLicenseKey('Using_this_AG_Grid_Enterprise_key_( AG-048089 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( CREDEBT EXCHANGE LIMITED )_is_granted_a_( Multiple Applications )_Developer_License_for_( 1 ))_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_AG_Grid_Enterprise___This_key_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 18 November 2024 )____[v2]_MTczMTg4ODAwMDAwMA==480ab7edfe96fa6ecd32550c2f3532b4');

ModuleRegistry.registerModules([RangeSelectionModule, ]);
const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';

export function tokenGetter() {
  return localStorage.getItem('token');
}
@NgModule({
  declarations: [
    AppComponent,
    SpinnerComponent,
    LoginComponent,
    UploadsComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
    AppRoutingModule,
    RouterModule.forRoot([]),
    FullComponent,
    SidebarComponent,
    NavigationComponent,
    BsDatepickerModule.forRoot(),
    NgxSpinnerModule,
    NgxPaginationModule,
    AgGridAngular,
    NgSelectModule,
    SharedModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: [environment.url], // Replace with your API URL
        disallowedRoutes: [environment.url + 'login'] // Replace with your login URL
      }
    })
  ],
  providers: [
    AuthService,
    AuthGuard,
    provideUserIdleConfig({
      idle: environment.Idle,
      timeout: environment.TimeOut,
      ping: 120,
    }),
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    DatePipe,
    UploadsComponent,
    DecimalPipe,
    ChunkPipe
  ],
  exports: [UploadsComponent],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
