import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class WithdrawFundsFormProvider {
  investmentForm: FormGroup;

  constructor(private fb: FormBuilder) {}

  initWithdrawFundsForm(data?: any): FormGroup {
    return (this.investmentForm = this.fb.group({
      investorId: [data?.investorId],
      intermediaryId: [data?.intermediaryId],
      type: [data?.type || '', [Validators.required]],
      transactionType: ['Redemption'],
      date: [data?.date || null, [Validators.required]],
      faceValue: [+data?.faceValue || '', [Validators.required, Validators.pattern('^[0-9,.]*$')]],
      product: [data?.product || '', [Validators.required]],
    }));
  }
}
