import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs/internal/Observable';
import { BanksQueryParams } from '../shared/interfaces/banks-query-params';
import { Summary } from '../shared/interfaces/bank-response.interface';
import { FxrateQueryParamsInterface } from '../shared/interfaces/fxrate-interface';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  readonly baseUrl = environment.url;
  constructor(public http: HttpClient) {}

  postData(serviceName: string, data: any) {
    return this.http.post(this.buildUrl(serviceName), data, { headers: this.getHeaders() });
  }

  getData(serviceName: string) {
    return this.http.get(this.buildUrl(serviceName), { headers: this.getHeaders() });
  }

  deleteData(serviceName: string, id: any) {
    // Include headers in the request
    const options = { headers: this.getHeaders() };
    return this.http.delete(`${this.buildUrl(serviceName)}/${id}`, options);
  }

  getCurrencyWithBank() {
    const baseUrl = environment.url + 'banks/currencies-banks-accounts';
    return this.http.get(baseUrl, { headers: this.getHeaders() });
  }

  getBankSummary(params: Partial<BanksQueryParams>): Observable<Summary> {
    return this.http.get<Summary>(`${environment.url}banks/summary`, {
      params: this.queryCleaner(params),
    });
  }

  getFxRateData(params: Partial<FxrateQueryParamsInterface>): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}fx-rates?`, {
      params: this.queryCleaner(params),
    });
  }

  upload(file: any, type: any): Observable<any> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    console.log(type);

    let uploadType = 'upload';
    let baseUrl = environment.url + 'csv/' + uploadType;

    if (type == 'investment') {
      uploadType = 'upload';
      baseUrl = environment.url + 'investments/import';
    } else if (type == 'bank') {
      baseUrl = environment.url + 'banks/import';
      uploadType = 'banksUpload';
    } else if (type == 'ETR') {
      uploadType = 'etrUpload';
      baseUrl = environment.url + 'etr/import';
    }
    return this.http.post(baseUrl, formData);
  }

  uploadValidate(file: any, type: any): Observable<any> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    let uploadType = 'upload';
    let baseUrl = environment.url + 'csv/' + uploadType;
    if (type == 'investment') {
      baseUrl = environment.url + 'investments/validate-import';
    } else if (type == 'bank') {
      baseUrl = environment.url + 'banks/validate-import';
    } else if (type == 'ETR') {
      baseUrl = environment.url + 'etr/validate-import';
    }
    return this.http.post(baseUrl, formData);
  }

  queryCleaner<T>(params: Partial<T>): { [key: string]: any } {
    for (const paramsKey in params) {
      if (!params[paramsKey as keyof T] && params[paramsKey as keyof T] !== 0) {
        delete (params as any)[paramsKey];
      }
    }
    return params;
  }

  queryCleanerBulk<T>(params: Partial<T>): { [key: string]: any } {
    for (const paramsKey in params) {
      if (!params[paramsKey as keyof T] || params[paramsKey as keyof T] === '~') {
        (params as any)[paramsKey] = null;
      }
    }
    return params;
  }

  getHeaders(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
    });
  }

  buildUrl(serviceName: string): string {
    return `${this.baseUrl}${serviceName}`;
  }
}
