import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { getFormData } from 'src/app/shared/helpers/utils';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpService } from 'src/app/services/http.service';
import { WithdrawFundsFormProvider } from '../../withdrawFunds-form-provider';
import { WithdrawFundsHttpService } from '../../common/services/withdrawFunds-http.service';
import { WithdrawFund } from 'src/app/shared/interfaces/withdraw-fund-response.interface';
import { InvestmentsHttpService } from 'src/app/investments/common/services/investments-http.service';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import { INVESTOR_ID } from 'src/app/shared/enums/common.enum';

@Component({
  selector: 'app-withdraw-funds',
  templateUrl: './withdraw-funds.component.html',
  styleUrls: ['./withdraw-funds.component.scss'],
})
export class WithdrawFundsComponent {
  WithdrawFundForm: FormGroup;
  // todo need remove when can get from backend side
  tooltipData = {
    type: 'Type',
    ccy: 'Ccy',
    amount: 'Amount',
    note: 'Note',
    investorId: 'Investor',
    intermediaryId: 'Intermediary',
    faceValue: 'Face Value',
    date: 'Date',
    product: 'Product',
  };
  isSubmitted = false;
  noteText: string = 'The above funds will be remitted to: IBAN';
  type: { id: string; name: string }[];
  WithdrawFundData: WithdrawFund;
  allCurrencyBankAccountData: any = [];
  product: { id: string; name: string }[];
  investors: { id: string; name: string }[];
  investorId = INVESTOR_ID;
  constructor(
    private withdrawFundsHttpService: WithdrawFundsHttpService,
    private withdrawFundsFormProvider: WithdrawFundsFormProvider,
    public activeModal: NgbActiveModal,
    private investmentsHttpService: InvestmentsHttpService,
  ) {}

  ngOnInit(): void {
    this.WithdrawFundForm = this.withdrawFundsFormProvider.initWithdrawFundsForm(this.WithdrawFundData);
    this.getDropDownsData();
  }

  onSubmit() {
    const fieldsToFormat = ['date'];
    this.isSubmitted = true;
    if (this.WithdrawFundForm.invalid) {
      return;
    }
    let rawData = this.WithdrawFundForm.value;
    rawData = getFormData(rawData);
    fieldsToFormat.forEach((field) => {
      const date = rawData[field];
      if (date) {
        rawData[field] = field === 'months' ? moment(date).format('MM') : moment(`${date.year}-${date.month}-${date.day}`, 'YYYY-MM-DD').format('YYYY-MM-DD');
      }
    });
    rawData.investorId = this.investorId;
    rawData.faceValue = rawData.faceValue.replace(/,/g, '');
    this.doCreate(rawData);
  }

  doCreate(data: any): void {
    this.investmentsHttpService.postData('investments', data).subscribe({
      next: (response: any) => {
        this.closeModal();
        this.WithdrawFundForm.reset();
        Swal.fire({
          icon: 'success',
          text: 'Investment successfully added.',
          showConfirmButton: false,
          timer: 5000,
        });
      },
      error: (error) => {
        Swal.fire({
          icon: 'error',
          text: error.error.details || error.error.message,
          showConfirmButton: true,
          confirmButtonText: 'Ok',
        });
      },
    });
  }

  onSelect(event: any): void {
    this.isSubmitted = false;
  }

  private getDropDownsData(): void {
    this.withdrawFundsHttpService.getFilterData().subscribe((filterData) => {
      filterData.types.sort((a, b) => a.localeCompare(b));
      //Type
      this.type = [
        ...(filterData.types.map((type) => ({
          id: type,
          name: type,
        })) || []),
      ];

      // Product
      this.product = [
        ...(filterData.products.map((product) => ({
          id: product,
          name: product,
        })) || []),
      ];

      // intermediaries

      let foundInvestor: any = null;
      let foundIntermediary: any = null;
      for (let intermediary of filterData.intermediariesWithInvestors) {
        let investor = intermediary.investors.find((investor: any) => investor.id == this.investorId);
        if (investor) {
          foundInvestor = investor;
          foundIntermediary = intermediary;
          break;
        }
      }
      if (!this.investors) {
        this.investors = [];
      }
      if (foundInvestor && foundIntermediary) {
        this.investors.push({
          id: foundIntermediary.id,
          name: foundIntermediary.name,
        });
      }
      this.investors.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
    });
  }

  closeModal() {
    this.activeModal.dismiss();
  }
}
