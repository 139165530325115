import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class ReferFriendFormProvider {
  investmentForm: FormGroup;

  constructor(private fb: FormBuilder) {}

  initReferenceForm(data?: any): FormGroup {
    return (this.investmentForm = this.fb.group({
      investorId: [data?.investorId],
      name: [data?.name || '', Validators.required],
      email: [data?.email || '', [Validators.required, Validators.email]],
    }));
  }
}
