import { Component, OnInit, ViewChild, inject, Input, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ROUTES } from './menu-items';
import { RouteInfo } from './sidebar.metadata';
import { Router, RouterModule } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule, NgIf } from '@angular/common';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AddEditInvestmentsComponent } from 'src/app/investments/components/add-edit-investments/add-edit-investments.component';
import { WithdrawFundsComponent } from 'src/app/withdraw-funds/components/withdraw-funds/withdraw-funds.component';
import { ReferFriendComponent } from 'src/app/refer-friend/components/refer-friend/refer-friend.component';
@Component({
  selector: 'ngbd-modal-content',
  standalone: true,
  template: `
    <div class="modal-header" role="dialog" aria-modal="true">
      <h4 class="modal-title">Hi there!</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
      <app-uploads></app-uploads>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
    </div>
  `,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class NgbdModalContent {
  activeModal = inject(NgbActiveModal);
  @Input() name: string | undefined;
}
@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [RouterModule, CommonModule],
  templateUrl: './sidebar.component.html',
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class SidebarComponent implements OnInit {
  @ViewChild('projctModal') projctModal: any;
  showMenu = '';
  showSubMenu = '';
  isClassAdded = false;
  public sidebarnavItems: RouteInfo[] = [];
  popupTitles: string[] = ['Add Investment', 'Refer a Friend', 'Withdraw Funds'];

  constructor(
    private modalService: NgbModal,
    private router: Router,
  ) { }

  ngOnInit() {
    this.sidebarnavItems = ROUTES.filter(sidebarnavItem => sidebarnavItem);
  }

  // this is for the open close
  addExpandClass(element: string) {
    this.removeSideBarClass();
    if (element === this.showMenu) {
      this.showMenu = '0';
      this.showSubMenu = '0';
    } else {
      this.showMenu = element;
      this.showSubMenu = '0';
    }
    if (element == "Entities") {
      // Navigate to the default route if no submenu item is selected
      this.router.navigate(['/entities/organisation']);
      this.addExpandSubClass('Organisations');
    }
  }

  addExpandSubClass(element: string) {
    this.removeSideBarClass();
    if (element === this.showSubMenu) {
      this.showSubMenu = '0';
    } else {
      this.showSubMenu = element;
    }
  }

  openModal() {
    this.projctModal.show();
  }

  callModal(title: string) {
    this.removeSideBarClass();
    switch (title) {
      case 'Add Investment':
        this.modalService.open(AddEditInvestmentsComponent, { backdrop: true });
        break;
      case 'Refer a Friend':
        this.modalService.open(ReferFriendComponent, { backdrop: true });
        break;
      case 'Withdraw Funds':
        this.modalService.open(WithdrawFundsComponent, { backdrop: true });
        break;
    }
  }

  removeSideBarClass() {
    const element = document.getElementById('main-wrapper') as Element;
    element.classList.remove('show-sidebar');
  }

  shouldDisplayItem(sidebarnavItem: any): boolean {
    return  !sidebarnavItem.extralink && this.popupTitles.includes(sidebarnavItem.title);
  }
}

