import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'chunk',
})
export class ChunkPipe implements PipeTransform {
  transform(value: any[], size: number): any[][] {
    return Array(Math.ceil(value.length / size))
      .fill(0)
      .map((_, index) => value.slice(index * size, (index + 1) * size));
  }
}
