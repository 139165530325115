import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  {
    path: '/dashboard',
    title: 'Dashboard',
    icon: 'bi bi-speedometer2',
    class: '',
    extralink: false,
    image: './assets/images/icon/dashboard.svg',
    submenu: [],
  },
  {
    path: '',
    title: 'Add Investment',
    icon: 'bi bi-speedometer2',
    class: '',
    extralink: false,
    image: './assets/images/icon/add-investment.svg',
    submenu: [],
  },
  {
    path: '',
    title: 'Refer a Friend',
    icon: 'bi bi-speedometer2',
    class: '',
    extralink: false,
    image: './assets/images/icon/refer-friend.svg',
    submenu: [],
  },
  {
    path: '/financial-statement',
    title: 'Financial Statement',
    icon: 'bi bi-subitem-icon', // Provide a suitable icon for the submenu item
    class: '',
    extralink: false,
    image: './assets/images/icon/trade.svg',
    submenu: [], // Submenu of the submenu item if needed
  },
  // {
  //   path: '/etr-holdings',
  //   title: 'ETR Holdings',
  //   icon: 'bi bi-subitem-icon', // Provide a suitable icon for the submenu item
  //   class: '',
  //   extralink: false,
  //   image: './assets/images/icon/org.svg',
  //   submenu: [], // Submenu of the submenu item if needed
  // },
  // {
  //   path: '/bank-transactions',
  //   title: 'Bank Transactions',
  //   icon: 'bi bi-subitem-icon', // Provide a suitable icon for the submenu item
  //   class: '',
  //   extralink: false,
  //   image: './assets/images/icon/cash.svg',
  //   submenu: [], // Submenu of the submenu item if needed
  // },
  {
    path: '',
    title: 'Withdraw Funds',
    icon: 'bi bi-speedometer2',
    class: '',
    extralink: false,
    image: './assets/images/icon/withdraw-funds.svg',
    submenu: [],
  },
  {
    path: '/bank-details',
    title: 'Bank Details',
    icon: 'bi bi-subitem-icon', // Provide a suitable icon for the submenu item
    class: '',
    extralink: false,
    image: './assets/images/icon/cash.svg',
    submenu: [], // Submenu of the submenu item if needed
  },
  {
    path: '/profile/view-edit-profile',
    title: 'View / Edit Profile',
    icon: 'bi bi-subitem-icon', // Provide a suitable icon for the submenu item
    class: '',
    extralink: false,
    image: './assets/images/icon/person.svg',
    submenu: [], // Submenu of the submenu item if needed
  },
  {
    path: '/profile/change-password',
    title: 'Change Password',
    icon: 'bi bi-subitem-icon', // Provide a suitable icon for the submenu item
    class: '',
    extralink: false,
    image: './assets/images/icon/lock.svg',
    submenu: [], // Submenu of the submenu item if needed
  },
];

