import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { HttpService } from '../../services/http.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { EditUploadsService } from '../edit-uploads.service';

import { ICellRendererComp, ICellRendererParams } from 'ag-grid-community';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ColDef, IDateFilterParams, IMultiFilterParams, ModuleRegistry } from '@ag-grid-community/core';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { MultiFilterModule } from '@ag-grid-enterprise/multi-filter';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';

import { IOlympicData } from '../interfaces';
import { HttpClient } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
// Register the required feature modules with the Grid
ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  MultiFilterModule,
  SetFilterModule,
  MenuModule,
  ClipboardModule,
  FiltersToolPanelModule,
]);

@Component({
  selector: 'app-uploads',
  templateUrl: './uploads.component.html',
  styleUrls: ['./uploads.component.scss'],
})
export class UploadsComponent {
  showModal: any = false;
  submitted = false;
  isClassAdded: boolean = false;
  isClassEdited: boolean = false;
  response: any;
  uploadedFiles: any;
  total: number = 0;
  page: number = 1;
  showFullContent: boolean[] = [];
  sortColumn: string | null = null;
  sortDirection: 'asc' | 'desc' = 'asc';
  currentRecord: any;
  public maxSize: number = 7;
  public directionLinks: boolean = true;
  public autoHide: boolean = false;
  public responsive: boolean = true;
  investmentForm: any;
  savedFilterModel: any = localStorage.getItem('savedFilterModel');
  sortingState: any = localStorage.getItem('sortingState');
  headerArray: string[] = [
    'Id',
    'Bank Date',
    'Transaction Details',
    'Type',
    'Payment Out',
    'Receipt In',
    'Intermediary ID',
    'Intermediary',
    'Investor ID',
    'Investor name',
    'Transaction',
    'Ccy',
    'Face Value',
    'Date',
    'Buy Rate',
    'Commission',
    'Months',
    'Type 2',
    'Comments',
    'Old Transaction',
    'Old Investment',
  ];
  transactionType: string = '';
  etrType: string = '';
  showEtr: any = false;
  etrForm: any;
  isEtrClassAdded: boolean = false;

  @ViewChild('fileUploader') fileUploader!: ElementRef;

  resetFileUploader() {
    this.fileUploader.nativeElement.value = null;
  }
  // Inside your UploadsComponent class
  generateColumn(
    headerName: string,
    field: string,
    min_width: number,
    customwidth: any,
    type: string,
    filtertype: string,
  ): ColDef {
    return {
      headerName: headerName,
      field: field,
      filter: filtertype,
      tooltipValueGetter: (params) => {
        const count = params.node && params.node.allChildrenCount;
        if (count != null) {
          return params.value + ' (' + count + ')';
        }
        return params.value;
      },
      width: customwidth,
      colId: field,
      maxWidth: 800,
      minWidth: customwidth,
      cellClass: type == 'right' ? 'ag-right-aligned-cell' : 'ag-left',
      valueFormatter: (params) => {
        // Check if the current column is "Commission" or "Buy_Rate"
        if (field === 'Commission' || field === 'Buy_Rate') {
          // Use toFixed to format the value to two decimal places
          //return params.value;
          if (params.value != '') {
            let value = params.value.replace('%', '');
            return Number(value).toFixed(3) + '%';
          }
          return '';
        } else if (
          field === 'Payment_Out' ||
          field === 'Receipt_In' ||
          field === 'Face_Value'
        ) {
          let originalValue = params.value;
          let formattedValue = originalValue.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
          return formattedValue;
        } else {
          return params.value;
        }
      },
      filterParams: {
        filters: [
          {
            filter: 'agTextColumnFilter',
          },
          {
            filter: 'agSetColumnFilter',
          },
        ],
      },
    };
  }

  colDefs: ColDef[] = [
    {
      headerName: 'No',
      field: 'No',
      width: 40,
      colId: 'No',
      valueGetter: 'node.rowIndex + 1',
      filterParams: {
        maxNumConditions: 1,
      },
      sortable: false,
    },
    {
      headerName: 'ID',
      field: 'Id',
      filter: 'agTextColumnFilter',
      width: 55,
      colId: 'Id',
      filterParams: {
        maxNumConditions: 1,
      },
    },
    {
      headerName: 'Bank Date',
      field: 'Bank_Date',
      filter: 'agDateColumnFilter',
      width: 80,
      colId: 'Bank_Date',
      filterParams: filterParams,
    },
    {
      headerName: 'Transaction Details',
      field: 'Transaction_Details',
      filter: 'agTextColumnFilter',
      width: 150,
      colId: 'Transaction_Details',
      filterParams: {
        maxNumConditions: 1,
      },
    },
    {
      headerName: 'Type',
      field: 'Type',
      filter: 'agMultiColumnFilter',
      width: 80,
      colId: 'Type',
      filterParams: {
        maxNumConditions: 1,
        filters: [
          {
            filter: 'agTextColumnFilter',
            filterParams: {
              maxNumConditions: 1,
            },
          },
          {
            filter: 'agSetColumnFilter',
          },
        ],
      } as IMultiFilterParams,
    },
    {
      headerName: 'Payment Out',
      field: 'Payment_Out',
      filter: 'agTextColumnFilter',
      width: 90,
      colId: 'Payment_Out',
      cellClass: 'ag-right-aligned-cell',
      filterParams: {
        maxNumConditions: 1,
      },
      valueFormatter: (params) => {
        let originalValue = params.value;
        let formattedValue = '-';
        if (originalValue != 0) {
          formattedValue = originalValue.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
        }
        return formattedValue;
      },
    },
    {
      headerName: 'Receipt In',
      field: 'Receipt_In',
      filter: 'agTextColumnFilter',
      width: 90,
      colId: 'Receipt_In',
      cellClass: 'ag-right-aligned-cell',
      filterParams: {
        maxNumConditions: 1,
      },
      valueFormatter: (params) => {
        let originalValue = params.value;
        let formattedValue = '-';
        if (originalValue != 0) {
          formattedValue = originalValue.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
        }
        return formattedValue;
      },
    },
    {
      headerName: 'Intermediary ID',
      field: 'ORGNISATION',
      filter: 'agTextColumnFilter',
      width: 120,
      colId: 'ORGNISATION',
      cellClass: 'ag-right-aligned-cell',
      filterParams: {
        maxNumConditions: 1,
      },
    },
    {
      headerName: 'Intermediary',
      field: 'Intermediary',
      filter: 'agMultiColumnFilter',
      width: 110,
      colId: 'Intermediary',
      filterParams: {
        maxNumConditions: 1,
        filters: [
          {
            filter: 'agTextColumnFilter',
            filterParams: {
              maxNumConditions: 1,
            },
          },
          {
            filter: 'agSetColumnFilter',
          },
        ],
      } as IMultiFilterParams,
    },
    {
      headerName: 'Investor ID',
      field: 'Investor_ID',
      filter: 'agMultiColumnFilter',
      width: 100,
      colId: 'Investor_ID',
      cellClass: 'ag-right-aligned-cell',
      filterParams: {
        maxNumConditions: 1,
        filters: [
          {
            filter: 'agTextColumnFilter',
            filterParams: {
              maxNumConditions: 1,
            },
          },
          {
            filter: 'agSetColumnFilter',
          },
        ],
      } as IMultiFilterParams,
    },
    {
      headerName: 'Investor Name',
      field: 'Investor_name',
      filter: 'agMultiColumnFilter',
      width: 115,
      colId: 'Investor_name',
      filterParams: {
        maxNumConditions: 1,
        filters: [
          {
            filter: 'agTextColumnFilter',
            filterParams: {
              maxNumConditions: 1,
            },
          },
          {
            filter: 'agSetColumnFilter',
          },
        ],
      } as IMultiFilterParams,
    },
    {
      headerName: 'Transaction',
      field: 'Transaction',
      filter: 'agMultiColumnFilter',
      width: 90,
      colId: 'Transaction',
      filterParams: {
        maxNumConditions: 1,
        filters: [
          {
            filter: 'agTextColumnFilter',
            filterParams: {
              maxNumConditions: 1,
            },
          },
          {
            filter: 'agSetColumnFilter',
          },
        ],
      } as IMultiFilterParams,
    },
    {
      headerName: 'Ccy',
      field: 'Ccy',
      filter: 'agMultiColumnFilter',
      width: 60,
      colId: 'Ccy',
      filterParams: {
        maxNumConditions: 1,
        filters: [
          {
            filter: 'agTextColumnFilter',
            filterParams: {
              maxNumConditions: 1,
            },
          },
          {
            filter: 'agSetColumnFilter',
          },
        ],
      } as IMultiFilterParams,
    },
    {
      headerName: 'Face Value',
      field: 'Face_Value',
      filter: 'agTextColumnFilter',
      width: 80,
      colId: 'Face_Value',
      cellClass: 'ag-right-aligned-cell',
      filterParams: {
        maxNumConditions: 1,
      },
      valueFormatter: (params) => {
        let originalValue = params.value;
        let formattedValue = '-';
        if (originalValue != 0) {
          formattedValue = originalValue.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
        }
        return formattedValue;
      },
    },
    {
      headerName: 'Date',
      field: 'Date',
      filter: 'agDateColumnFilter',
      width: 80,
      colId: 'Date',
      filterParams: filterParams,
    },
    {
      headerName: 'Rate',
      field: 'Buy_Rate',
      filter: 'agTextColumnFilter',
      width: 60,
      colId: 'Buy_Rate',
      cellClass: 'ag-right-aligned-cell',
      filterParams: {
        maxNumConditions: 1,
      },
      valueFormatter: (params) => {
        let originalValue = params.value;
        if (originalValue != '') {
          return originalValue + '%';
        }
        return '';
      },
    },
    {
      headerName: 'Comm',
      field: 'Commission',
      filter: 'agTextColumnFilter',
      width: 60,
      colId: 'Commission',
      cellClass: 'ag-right-aligned-cell',
      filterParams: {
        maxNumConditions: 1,
      },
      valueFormatter: (params) => {
        let originalValue = params.value;
        if (originalValue != '') {
          return originalValue + '%';
        }
        return '';
      },
    },
    {
      headerName: 'MM',
      field: 'Months',
      filter: 'agTextColumnFilter',
      width: 60,
      colId: 'Months',
      cellClass: 'ag-center-aligned-cell',
      filterParams: {
        maxNumConditions: 1,
      },
    },
    {
      headerName: 'Type 2',
      field: 'Type2',
      filter: 'agTextColumnFilter',
      width: 80,
      colId: 'Type2',
      filterParams: {
        maxNumConditions: 1,
      },
    },
    {
      headerName: 'Comments',
      field: 'Comments',
      filter: 'agTextColumnFilter',
      width: 80,
      colId: 'Comments',
      filterParams: {
        maxNumConditions: 1,
      },
    },
    {
      headerName: 'Old Transaction',
      field: 'Old_Transaction',
      filter: 'agMultiColumnFilter',
      width: 125,
      colId: 'Old_Transaction',
      filterParams: {
        maxNumConditions: 1,
        filters: [
          {
            filter: 'agTextColumnFilter',
            filterParams: {
              maxNumConditions: 1,
            },
          },
          {
            filter: 'agSetColumnFilter',
          },
        ],
      } as IMultiFilterParams,
    },
    {
      headerName: 'Old Inv.',
      field: 'Old_Investment',
      filter: 'agTextColumnFilter',
      width: 80,
      colId: 'Old_Investment',
      filterParams: {
        maxNumConditions: 1,
      },
    },
    {
      field: 'Action',
      pinned: 'right',
      cellRenderer: ShowCellRenderer,
      cellDataType: false,
      width: 60,
    },
  ];

  rowData!: IOlympicData[];
  originRowData!: IOlympicData[];
  defaultColDef: ColDef = {
    minWidth: 0,
    floatingFilter: true,
  };

  pageSizes = [50, 100, 200, 500, 1000];
  selectedPageSize = 100;
  paginationPageSize = 100;
  gridApi: any;

  bsValue = new Date();
  bsRangeValue: Date[] | undefined;
  minDate: Date = new Date();
  maxDate: Date = new Date();
  dateRange: any = null;
  currentFilter: any = 'All';
  financialList: any;
  selectedFinancialYear: any = '';

  uploadTypeId: any = 0;
  subTypeId: any = 0;
  transaction: any = '';
  investorType: any = '';
  etr: any = '';
  subTypeTitle: any = 'Data Type';
  showButtons: boolean = false;
  showUploadLoader: boolean = false;
  showValidateLoader: boolean = false;
  showSubtype: any = false;
  showTransaction: any = false;
  showInvestor: any = false;
  showEtrDataTypes: any = false;
  etrName: string = '';
  uploadTypes: any = [
    { id: 1, name: 'Investment' },
    { id: 2, name: 'ETR' },
    { id: 3, name: 'Bank' },
  ];

  subTypes: any;

  dataTypes: any = [
    { id: 1, name: 'Manual Entry' },
    { id: 2, name: 'Upload Data' },
  ];
  etrTypes: any = [
    { id: 1, name: 'a-ETR' },
    { id: 2, name: 'b-ETR' },
    { id: 3, name: 'c-ETR' },
    { id: 4, name: 'd-ETR' },
    { id: 5, name: 'f-ETR' },
    { id: 6, name: 'g-ETR' },
  ];
  bankTypes: any = [
    { id: 1, name: 'Barclays Bank' },
    { id: 2, name: 'Piraeus Bank' },
    { id: 3, name: 'Danske Bank' },
  ];

  transactions: any = [
    { id: 'Receipt', name: 'Receipt' },
    { id: 'Compound', name: 'Compound' },
    { id: 'Redemption', name: 'Redemption' },
  ];

  investorTypes: any = [
    { id: 1, name: 'New Investor' },
    { id: 2, name: 'Existing Investor' },
  ];
  constructor(
    private http: HttpService,
    private spinner: NgxSpinnerService,
    public editUploadsService: EditUploadsService,
    private el: ElementRef,
    private renderer: Renderer2,
    private apiClient: HttpClient,
    private modalService: NgbModal,
  ) {}
  ngOnInit(): void {
    this.investmentForm = this.editUploadsService.investmentForm;
    this.etrForm = this.editUploadsService.etrForm;
    this.financialList = this.editUploadsService.financialYear;
  }

  onSelect(event: any) {
    this.uploadTypeId = event?.target?.value;
    if (this.uploadTypeId == 1) {
      this.showButtons = false;
      this.showSubtype = true;
      this.subTypes = this.dataTypes;
      this.subTypeTitle = 'Data Type';
      this.subTypeId = 0;
    } else if (this.uploadTypeId == 2) {
      this.showButtons = false;
      this.showSubtype = true;
      this.subTypes = this.etrTypes;
      this.subTypeTitle = 'ETR Type';
      this.subTypeId = 0;
    } else if (this.uploadTypeId == 3) {
      this.showButtons = true;
      this.showSubtype = false;
      this.subTypes = this.bankTypes;
      this.subTypeTitle = 'Bank Type';
    } else {
      this.subTypes = [];
    }
    this.showTransaction = false;
    this.showEtrDataTypes = false;
    this.showInvestor = false;
  }
  showLoader() {
    this.spinner.show();
  }

  hideLoader() {
    this.spinner.hide();
  }
  onSelectSubType(event: any) {
    if (this.uploadTypeId == 1 && this.subTypeId == 1) {
      this.showInvestor = true;
      this.investorType = '';
      this.showButtons = false;
      this.showTransaction = false;
      this.showEtr = false;
      this.showEtrDataTypes = false;
    } else if (this.uploadTypeId == 2) {
      this.showButtons = false;
      this.showEtr = false;
      this.showTransaction = false;
      this.showInvestor = false;
      const etrTypesMap = this.etrTypes.reduce(
        (acc: Record<number, string>, obj: (typeof this.etrTypes)[number]) => {
          acc[obj.id] = obj.name;
          return acc;
        },
        {} as Record<number, string>,
      );
      this.etrName = etrTypesMap[event?.target?.value];
      if (
        event?.target?.value == '1' ||
        event?.target?.value == '5' ||
        event?.target?.value == '6'
      ) {
        this.showEtrDataTypes = true;
      } else {
        this.showEtr = false;
        this.showButtons = true;
        this.showEtrDataTypes = false;
      }
    } else {
      this.showButtons = true;
      this.showEtr = false;
      this.showTransaction = false;
      this.showEtrDataTypes = false;
      this.showInvestor = false;
    }
  }

  onSelectTransaction(event: any) {
    this.transactionType = event?.target?.value;
    this.showButtons = false;
    this.openModal('add');
  }

  onSelectInvestor(event: any) {
    this.investorType = event?.target?.value;
    this.showTransaction = true;
  }

  onSelectEtr(event: any) {
    this.etrType = event?.target?.value;
    this.showButtons = false;
    this.openEtrModal('add');
    localStorage.setItem('etr_value', event?.target?.value);
  }

  onSelectEtrDataType(event: any) {
    if (event?.target?.value == 1) {
      this.showEtr = true;
      this.showButtons = false;
      this.openEtrModal('add');
    } else {
      this.showEtr = false;
      this.showButtons = true;
    }
  }

  ngAfterViewInit() {
    const dateInputs =
      this.el.nativeElement.querySelectorAll('input[type="date"]');

    dateInputs.forEach((input: HTMLInputElement) => {
      this.renderer.listen(input, 'click', () => this.clearDateData(input));
      this.renderer.listen(input, 'change', () => this.updateDateData(input));

      // Trigger initial change event
      this.renderer.setProperty(input, 'value', '');
      input.dispatchEvent(new Event('change'));
    });
  }
  onDateRangeChange(event: any) {
    if (event && event[0] !== undefined) {
      const startDate = moment(event[0]).format('YYYY-MM-DD');
      const endDate = moment(event[1]).format('YYYY-MM-DD');
      this.rowData = this.originRowData.filter(
        (m) =>
          new Date(m.Date) >= new Date(startDate) &&
          new Date(m.Date) <= new Date(endDate),
      );
    } else {
      this.rowData = this.originRowData;
    }
    this.gridApi.refreshCells();
  }
  financialChange() {
    if (this.selectedFinancialYear != '') {
      const selectedYear = this.selectedFinancialYear;
      this.rowData = this.originRowData.filter((m) => {
        const bankDate = new Date(m.Date);
        const year = bankDate.getFullYear();
        return year == selectedYear;
      });
    } else {
      this.rowData = this.originRowData;
      this.gridApi.refreshCells();
    }
  }
  onFilterChanged(event: any) {
    if (event.api != undefined) {
      event.api.refreshCells();
    }
  }

  clearDateData(input: HTMLInputElement) {
    this.renderer.setProperty(input, 'value', ''); // Clear value
    this.renderer.setAttribute(input, 'data-date', '');
    input.dispatchEvent(new Event('change')); // Corrected line
    this.resetFilters();
  }

  showData(type: string) {
    this.selectedFinancialYear = '';
    this.dateRange = '';
    this.rowData = this.originRowData;
    this.gridApi.refreshCells();
    if (type == 'All') {
      this.currentFilter = type;
    } else if (type == 'Date') {
      this.currentFilter = type;
    } else if (type == 'Year') {
      this.currentFilter = type;
    }
  }

  updateDateData(input: HTMLInputElement) {
    const inputValue = input.value;

    if (inputValue !== '') {
      this.renderer.setAttribute(
        input,
        'data-date',
        moment(inputValue, 'YYYY-MM-DD').format('YYYY-MM-DD'),
      );
    }
  }

  reloadData() {
    this.triggerClickOnDateInputs();
    this.http.getData('investment').subscribe(
      (response: any) => {
        this.rowData = response.data;
      },
      (error) => {
        console.error('Error:', error);
      },
    );
  }
  resetFilters() {
    let listDate = ['Bank_Date', 'Date'];
    listDate.forEach((listDate) => {
      const filterComponent = this.gridApi.getFilterInstance(listDate);
      if (filterComponent) {
        filterComponent.setModel(null);
        this.gridApi.onFilterChanged();
      }
    });
  }
  triggerClickOnDateInputs() {
    const dateInputs =
      this.el.nativeElement.querySelectorAll('input[type="date"]');
    dateInputs.forEach((input: any) => {
      this.triggerClickEventOnDateInput(input);
    });
  }
  triggerClickEventOnDateInput(input: any) {
    this.renderer.listen(input, 'click', () => {
    });
    this.renderer.selectRootElement(input).click();
  }

  fileChangeValidate(element: any) {
    this.uploadedFiles = element.target.files[0];
    this.showValidateLoader = true;
    let uploadType = 'investment';
    if (this.subTypeTitle == 'Data Type' && this.uploadTypeId == 1) {
      uploadType = 'investment';
    } else if (this.subTypeTitle == 'Bank Type' && this.uploadTypeId == 3) {
      uploadType = 'bank';
    } else {
      uploadType = 'ETR';
    }
    this.http.uploadValidate(this.uploadedFiles, uploadType).subscribe(
      (event: any) => {
        this.showValidateLoader = false;
        this.modalService.dismissAll();
        Swal.fire({
          icon: 'success',
          text: 'File successfully validated.',
          showConfirmButton: true,
          confirmButtonText: 'Ok',
        });
        element.target.files = null;
        this.resetFileUploader();
      },
      (error) => {
        this.modalService.dismissAll();
        this.showValidateLoader = false;
        console.error('Error:', error);
        let errorMessage = error.error.message;
        if (Array.isArray(error.error.message)) {
          errorMessage = this.generateStringFromArrayWithLineBreak(
            error.error.message,
          );
        }
        Swal.fire({
          title: 'Error',
          icon: 'error',
          html: '<div class="poprow"> ' + errorMessage + '</div>',
          showConfirmButton: true,
          confirmButtonText: 'Close',
        }).then((result) => {
          if (result.isConfirmed) {
            // Handle confirm button click
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            // Handle cancel button click
          }
        });
        this.resetFileUploader();
        // Handle errors
      },
    );
  }
  fileChange(element: any) {
    this.uploadedFiles = element.target.files[0]
    let uploadType = 'investment';
    if (this.subTypeTitle == 'Data Type' && this.uploadTypeId == 1) {
      uploadType = 'investment';
    } else if (this.subTypeTitle == 'Bank Type' && this.uploadTypeId == 3) {
      uploadType = 'bank';
    } else {
      uploadType = 'ETR';
    }
    this.showUploadLoader = true;
    this.http.upload(this.uploadedFiles, uploadType).subscribe(
      (event: any) => {
        this.showUploadLoader = false;

        this.modalService.dismissAll();
        Swal.fire({
          icon: 'success',
          text: 'File successfully Uploaded.',
          showConfirmButton: true,
          confirmButtonText: 'Ok',
        });
        element.target.files = null;
        this.resetFileUploader();
      },
      (error) => {
        this.showUploadLoader = false;
        this.modalService.dismissAll();
        console.error('Error:', error);
        let errorMessage = error.error.message;
        if (Array.isArray(error.error.message)) {
          errorMessage = this.generateStringFromArrayWithLineBreak(
            error.error.message,
          );
        }
        Swal.fire({
          title: 'Error',
          icon: 'error',
          html: '<div class="poprow"> ' + errorMessage + '</div>',
          showConfirmButton: true,
          confirmButtonText: 'Close',
        }).then((result) => {
          if (result.isConfirmed) {
            // Handle confirm button click
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            // Handle cancel button click
          }
        });
        this.resetFileUploader();
        // Handle errors
      },
    );
  }
  generateStringFromArrayWithLineBreak(array: string[]): string {
    let result = '';
    array.forEach((item, index) => {
      result += item;
      if (index < array.length - 1) {
        result += '<hr></br>'; // Add line break if not the last item
      }
    });
    return result;
  }
  openEtrModal(type: any, res: any = '') {
    if (type == 'add') {
      this.isEtrClassAdded = true;
      this.etrForm.reset();
    }
  }

  openModal(type: any, res: any = '') {
    if (type == 'add') {
      this.isClassAdded = true;
      this.investmentForm.reset();
    } else {
      for (const header of this.headerArray) {
        const control = header.replace(/\s/g, '_');
        // Check if the control exists in the form
        if (this.investmentForm.controls[control]) {
          if (res[control] !== undefined) {
            if (control == 'Bank_Date' || control == 'Date') {
              const date = {
                year: parseInt(moment(res[control]).format('YYYY'), 10),
                day: parseInt(moment(res[control]).format('DD'), 10),
                month: parseInt(moment(res[control]).format('M'), 10),
              };
              this.investmentForm.controls[control].setValue(date);
            } else if (
              control == 'Payment_Out' ||
              control == 'Face_Value' ||
              control == 'Receipt_In'
            ) {
              const originalValue = res[control].toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              });
              this.investmentForm.controls[control].setValue(originalValue);
            } else {
              this.investmentForm.controls[control].setValue(res[control]);
            }
          } else {
            console.error(`Value for ${control} is undefined.`);
          }
        } else {
          console.error(`Control ${control} does not exist in the form.`);
        }
      }
      this.currentRecord = res;
      this.isClassEdited = true;
    }
  }

  closeModal() {
    this.isClassAdded = false;
    this.isClassEdited = false;
    this.transaction = '';
  }

  closeEtrModal() {
    this.isEtrClassAdded = false;
    this.isClassEdited = false;
    this.transaction = '';
    this.etr = '';
  }

  closeModal1() {}

  Delete(id: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
      }
    });
  }

  onPageSizeChange(): void {
    this.paginationPageSize = this.selectedPageSize;
  }

  toggleFullScreen() {
    const elem = this.el.nativeElement;

    if (!document.fullscreenElement) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        // Firefox
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        // Chrome, Safari and Opera
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        // IE/Edge
        elem.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  }

  restoreFilterModel() {
    if (this.savedFilterModel != '') {
      this.gridApi.setFilterModel(JSON.parse(this.savedFilterModel));
    }
    if (this.sortingState != '') {
      const restore = JSON.parse(this.sortingState);
      const newArray = [];
      for (const reset of restore) {
        const { colId, width, sort, sortIndex } = reset;
        const newObject = { colId, width, sort, sortIndex };
        newArray.push(newObject);
      }

      this.gridApi.applyColumnState({
        state: newArray,
        applyOrder: true,
      });
    }
  }

  resetFilter() {
    localStorage.setItem('sortingState', '');
    localStorage.setItem('savedFilterModel', '');
    this.gridApi.setFilterModel(null);
    const newArray = [];
    for (const reset of this.colDefs) {
      const { field, width } = reset;
      const newObject = { colId: field, width };
      newArray.push(newObject);
    }
    this.gridApi.applyColumnState({
      state: newArray,
      applyOrder: true,
    });
  }

  onSortChanged(event: any) {
    const sortingState = this.gridApi.getColumnState();
    localStorage.setItem('sortingState', JSON.stringify(sortingState));
    this.savedFilterModel = this.gridApi.getFilterModel();
    localStorage.setItem(
      'savedFilterModel',
      JSON.stringify(this.savedFilterModel),
    );
    if (event.api != undefined) {
      event.api.refreshCells();
    }
  }
  onFilterTextChange(event: any) {
  }
  validateFile() {}
  downloadTemplate() {
    let filename = 'investments_template.csv';
    let apiUrl = 'assets/resources/investments_template.csv';

    if (this.subTypeTitle == 'Data Type' && this.uploadTypeId == 1) {
      filename = 'investments_template.csv';
      apiUrl = 'assets/resources/investments_template.csv';
    } else if (this.subTypeTitle == 'Bank Type' && this.uploadTypeId == 3) {
      filename = 'banks_template.csv';
      apiUrl = 'assets/resources/banks_template.csv';
    } else if (this.subTypeTitle == 'ETR Type' && this.uploadTypeId == 2) {

      switch (this.etrName) {
        case 'a-ETR':
          filename = 'a_etr_template.csv';
          apiUrl = 'assets/resources/a_f_etr_template.csv';
          break;
        case 'f-ETR':
          filename = 'f_etr_template.csv';
          apiUrl = 'assets/resources/a_f_etr_template.csv';
          break;
        case 'b-ETR':
          filename = 'b_etr_template.csv';
          apiUrl = 'assets/resources/b_c_etr_template.csv';
          break;
        case 'c-ETR':
          filename = 'c_etr_template.csv';
          apiUrl = 'assets/resources/b_c_etr_template.csv';
          break;
        case 'd-ETR':
          filename = 'd_etr_template.csv';
          apiUrl = 'assets/resources/d_etr_template.csv';
          break;
        case 'g-ETR':
          filename = 'g_etr_template.csv';
          apiUrl = 'assets/resources/etr_template.csv';
          break;
        default:
          filename = 'etr_template.csv';
          apiUrl = 'assets/resources/etr_template.csv';
      }
    }

    this.apiClient
      .get(apiUrl, { responseType: 'arraybuffer' })
      .subscribe((data: ArrayBuffer) => {
        const blob = new Blob([data], { type: 'text/csv' });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  }
}

@Component({
  selector: 'colour-cell',
  template: `<div class="action">
    <button
      type="button"
      class="btn btn-link btn-custom-link"
      (click)="openModalEdit('edit', params)"
    >
      <img src="./assets/images/icon/edit.svg" />
    </button>
    <button
      type="button"
      class="btn btn-link btn-custom-link"
      (click)="DeleteInvest(1)"
    >
      <img src="./assets/images/icon/trash.svg" />
    </button>
  </div>`,
})
class ShowCellRenderer implements ICellRendererComp {
  constructor(private sharedService: UploadsComponent) {}
  ui: any;
  params!: ICellRendererParams;
  agInit(params: ICellRendererParams) {
    this.params = params.data;
  }

  openModalEdit(type: any, res: any) {
    this.sharedService.openModal(type, res);
  }
  DeleteInvest(id: any) {
    this.sharedService.Delete(id);
  }

  getGui() {
    return this.ui;
  }

  refresh() {
    return false;
  }
}

var filterParams: IDateFilterParams = {
  comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
    var dateAsString = cellValue;
    if (dateAsString == null) return -1;
    var dateParts = dateAsString.split('-');
    var cellDate = new Date(
      Number(dateParts[0]),
      Number(dateParts[1]) - 1,
      Number(dateParts[2]),
    );
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
    return 0;
  },
  minValidYear: 2000,
  maxValidYear: 2030,
  inRangeFloatingFilterDateFormat: 'Do MMM YYYY',
};
