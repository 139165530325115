import { Observable } from 'rxjs';
import { HttpService } from '../../../services/http.service';
import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { ReferFriendRequest, ReferFriendResponse } from '../interfaces/referFriend-interface';

@Injectable({ providedIn: 'root' })
export class ReferFriendHttpService extends HttpService {
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'API-KEY': '77d9fbffeb8b41092c0259694ae61e00910fe0c9',
    'API-SECRET': '9e30fd67a66210f2a91efff26d39b1e3',
  });
  url = 'https://api.test.dealmakersystem.com/dm/create-lead-from-guest';

  referFriend(payload: ReferFriendRequest): Observable<any> {
    return this.http.post<any>(this.url, payload, { headers: this.headers });
  }
}
