<div 
     class="modal fade show custom_popup"
     tabindex="-1"
     role="dialog"
>
    <div class="modal-dialog modal-xl modal-dialog-scrollable modal-dialog-top" role="document">
        <div class="modal-content">
            <div class="modal-header justify-content-between">
                <h5 class="modal-title">
                    Refer a friend
                </h5>
                <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        (click)="closeModal()"
                >
                    <span>&times;</span>
                </button>
            </div>
            <div class="modal-body padding-model form-editing">
              
<div class="row">
    <div class="col-lg-12">
        <div class="card w-100">
            <div class="card">
                <form
                    *ngIf="referenceForm"
                    [formGroup]="referenceForm"
                    (ngSubmit)="onSubmit()"
                >
                    <div class="row">         
                        <div class="col-md-4" >
                            <div class="mt-3">
                                <label for="name" class="form-title"> Name
                                    <span
                                        class="tooltip-ng ms-0"
                                        placement="bottom"
                                        ngbTooltip="{{ tooltipData.name }}"
                                    ><i class="bi bi-question-circle"></i></span
                                    ></label>
                                <input
                                    id="name"
                                    type="text"
                                    class="form-control ft-width"
                                    placeholder=""
                                    formControlName="name"
                                />
                            </div>
                            <app-form-error-component [control]="referenceForm.controls['name']" />
                        </div>

                        <div class="col-md-6">
                            <div class="mt-3">
                                <label for="email" class="form-title"> Email
                                    <span
                                        class="tooltip-ng ms-0"
                                        placement="bottom"
                                        ngbTooltip="{{ tooltipData.email }}"
                                    ><i class="bi bi-question-circle"></i></span
                                    ></label>
                                <input
                                    id="email"
                                    type="text"
                                    class="form-control ft-width"
                                    placeholder=""
                                    formControlName="email"
                                 
                                />
                            </div>
                            <app-form-error-component [control]="referenceForm.controls['email']" />
                        </div>
                        <div class="col-12 bottom-fixed">
                            <hr />
                            <div class="d-flex justify-content-center mt-3">
                                <button [disabled]="referenceForm.invalid" type="submit" class="btn btn-light me-3 btn-custom-footer save_btn">
                                    Save
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-light btn-custom-footer cancel_btn"
                                    data-dismiss="modal"
                                    (click)="closeModal()"
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
            </div>
        </div>
    </div>
</div>
